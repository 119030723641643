<div class="sidebar-area bg-white transition" [ngClass]="{ active: isToggled }">
  <div
    class="burger-menu transition cursor-pointer"
    [ngClass]="{ active: isToggled }"
    (click)="toggle()"
  >
    <span class="top-bar transition bg-dark d-block"></span>
    <span class="middle-bar transition bg-dark d-block"></span>
    <span class="bottom-bar transition bg-dark d-block"></span>
  </div>
  <div class="logo bg-white">
    <a routerLink="/" class="d-flex align-items-center justify-content-center">
      <img
        *ngIf="sessionUserType === 'Patient' && !isToggled"
        alt="logo"
        src="assets/images/ThriveHubLogopng.png"
      />
      <img
        *ngIf="sessionUserType !== 'Patient' && !isToggled"
        alt="logo-icon"
        src="assets/images/VitalyticsLogopng.png"
      />

      <img
        *ngIf="isToggled"
        src="assets/images/sidebar/logo.png"
        alt="logo-icon"
        style="width: 56px"
      />
      <!-- <span class="fw-extrabold"> Trinta </span> -->
    </a>
  </div>

  <ng-scrollbar visibility="hover">
    <div class="sidebar-inner">
      <div class="d-flex align-items-center flex-column">
        <img
          src="../../../../assets/images/pp.png"
          class="rounded-circle"
          alt="admin-image"
          style="width: 90px"
        />
        <div class="title" style="margin: 10px 0px 15px 0px">
          <a routerLink="/my-profile" class="d-inline-block fw-medium">
            {{ this.userInfo.firstName }} {{ this.userInfo.lastName }}
          </a>
        </div>
      </div>

      <div class="sidebar-menu">
        <mat-accordion>
          @for (item of menu; track item) {
          <a
            [routerLink]="item.path"
            class="sidebar-menu-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i-feather name="calendar"></i-feather>
            <span class="title">{{ item.name }}</span>
          </a>
          }

          <!-- Staff Links -->
          @if (this.sessionUserType === 'Staff') {
          <a
            routerLink="/doctor/profile-setting"
            class="sidebar-menu-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i-feather name="user"></i-feather>
            <span class="title">Profile Setting</span>
          </a>

          <a *ngIf="hasPermission('SETTING_MODULE')"
          routerLink="/setting"
          class="sidebar-menu-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <i-feather name="user"></i-feather>
          <span class="title">Setting</span>
        </a>
        <a *ngIf="hasPermission('ORGANIZATION_REPORT')"
        routerLink="unidashboard/masterreport"
        class="sidebar-menu-link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
      <i-feather name="bar-chart-2"></i-feather>
      <span class="title">Organization Reports</span>
      </a>
      <a *ngIf="hasPermission('BRANCH_REPORT')"
        routerLink="unidashboard/patientoverview"
        class="sidebar-menu-link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >
      <i-feather name="bar-chart-2"></i-feather>
      <span class="title">Branch Reports</span>
      </a>
          <a
            routerLink="/campaign"
            class="sidebar-menu-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i-feather name="globe"></i-feather>
            <span class="title">Campaign</span>
          </a>
          }

          <!-- Other Logout -->
          @if(this.sessionUserType === 'Staff') {
          <a
            href="#"
            (click)="logout($event)"
            class="sidebar-menu-link"
            routerLinkActive="active"
          >
            <i-feather name="log-out"></i-feather>
            <span class="title">Logout</span>
          </a>
          }

          <!-- Patient Logout -->
          @if (this.sessionUserType ==='Patient') {
          <a
            href="#"
            (click)="patientLogout($event)"
            class="sidebar-menu-link"
            routerLinkActive="active"
          >
            <i-feather name="log-out"></i-feather>
            <span class="title">Logout</span>
          </a>
          }
        </mat-accordion>
      </div>

    <p *ngIf="!isToggled"  [ngStyle]="{'color': locationPermission ? 'var(--primaryColor)' : 'red'}" style="text-align: center;">{{ message }} <mat-icon *ngIf="!locationPermission" style="cursor: pointer;" [matTooltip]="openBrowserSpecificSteps()" >info</mat-icon></p>
    <img *ngIf="logoUrl" class="facility-logo" width="150" [src]="logoUrl">
    <div
        class="branch-switch"
        *ngIf="sessionUserType === 'Staff' && orgBranch"
      >
        <branch-selection></branch-selection>
      </div>
    </div>
  </ng-scrollbar>
</div>

<!-- Old -->
<!-- <div class="sidebar_menu" #sidenav>
  <p class="user-type">{{ this.userType }}</p>
  <mat-nav-list class="menu-list">
    <mat-nav-list>
      @for (item of menu; track item) {
      <span>
        <mat-list-item
          [routerLink]="item.path"
          [routerLinkActive]="'active-link'"
          matTooltip="{{ item.name }}"
          [routerLinkActiveOptions]="{ exact: true }"
          fxLayoutAlign="center center"
          (click)="onToggleClose()"
          matTooltipPosition="right"
        >
          <div class="active" fxLayoutAlign="center center" fxLayout="row">
            <img [src]="item.icon" alt="" width="24px" height="24px" />
          </div>
        </mat-list-item>
      </span>
      }
      <mat-divider></mat-divider>
      @if (this.userType=='Doctor') {
      <mat-list-item
        matTooltip="Profile Setting"
        fxLayoutAlign="center center"
        (click)="onToggleClose()"
        matTooltipPosition="right"
        [routerLink]="['/doctor/profile-setting']"
        routerLinkActive="router-link-active"
      >
        <div class="active" fxLayoutAlign="center center" fxLayout="row">
          <mat-icon class="icon nav-icon">settings</mat-icon>
        </div>
      </mat-list-item>
      <mat-list-item
        matTooltip="Campaign"
        fxLayoutAlign="center center"
        (click)="onToggleClose()"
        matTooltipPosition="right"
        [routerLink]="['/campaign']"
        routerLinkActive="router-link-active"
      >
        <div class="active" fxLayoutAlign="center center" fxLayout="row">
          <img
            src="../../../../assets/images/campaign.svg"
            alt=""
            height="24px"
            width="24px"
          />
        </div>
      </mat-list-item>
      }
      @if (this.userType=='Patient') {
      <mat-list-item
        matTooltip="Logout"
        fxLayoutAlign="center center"
        (click)="patientLogout()"
        matTooltipPosition="right"
      >
        <div class="active" fxLayoutAlign="center center" fxLayout="row">
          <mat-icon class="icon nav-icon">exit_to_app</mat-icon>
        </div>
      </mat-list-item>
      } @if (this.userType=='Doctor') {
      <mat-list-item
        matTooltip="Logout"
        fxLayoutAlign="center center"
        (click)="logout()"
        matTooltipPosition="right"
      >
        <div class="active" fxLayoutAlign="center center" fxLayout="row">
          <mat-icon class="icon nav-icon">exit_to_app</mat-icon>
        </div>
      </mat-list-item>
      }
    </mat-nav-list>
  </mat-nav-list>
</div> -->
