import { CommonModule, NgClass } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive, ActivatedRoute, Router } from '@angular/router';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LayoutComponent } from './layout/layout.component';
import { WebpageComponent } from './modules/webpage/webpage.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgClass, CommonModule, RouterOutlet, RouterLink, RouterLinkActive, LoaderComponent, LayoutComponent, WebpageComponent]
})
export class AppComponent {
  title = 'neemandtulsi';
  portal: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  ngAfterViewInit(): void {
    const divsWithStyle = document.querySelectorAll('div');
    divsWithStyle.forEach(div => {
      if (div.innerText == 'This application was built using a trial version of Syncfusion Essential Studio. To remove the license validation message permanently, a valid license key must be included. Claim your free account') {
        div.style.display = 'none';
      }
    });
  }

  ngOnInit(): void {
    var string = '?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0N2E3YmM5OC1iYmExLTRlZDEtOTkzNy02MjdhYmRiOTAxODgiLCJqdGkiOiIyNTA3MTUwOS02NmRhLTQ5NDQtOTZiNi1iNmNhM2E2OTI0ODYiLCJleHAiOjE3MTY4MDI2MDgsImlzcyI6IkVudml2byIsImF1ZCI6IkVudml2byJ9.Tg1UHEDtfdiFnXMG85cclUwdrQ-A7YzxzpdE6bh_2CM'
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain === 'alpha') {
      this.portal = true
    } else if (subdomain === 'beta') {
      this.portal = true
    } else if (subdomain === 'trial') {
      this.portal = true
    } else if (subdomain === 'portal') {
      this.portal = true
    } else if (subdomain === 'dev-portal') {
      this.portal = true
    } else if (subdomain === 'localhost') {
      this.portal = true
    } else if (subdomain === 'stagenat') {
      this.portal = true
    } else if (subdomain === 'prodnat') {
      this.portal = true
    } else if (subdomain === 'dev-oversight') {
      this.portal = true
      localStorage.setItem('isUniversityLogin','Y');
    } else if (subdomain === 'oversight') {
      this.portal = true
      localStorage.setItem('isUniversityLogin','Y');
    } else {
      this.portal = false
    }
    // const hostname = window.location.hostname;
    // // Parse the subdomain
    // const subdomain = hostname.split('.')[0];

    // // Route based on the subdomain
    // switch (subdomain) {
    //   case 'stage-portal':
    //     this.portal = true
    //     break;
    //   case 'stagenat':
    //     this.portal = true
    //     break;
    //   case 'harshj':
    //     this.portal = false
    //     break;
    //   default:
    //     this.portal = true
    //     break;
    // }
  }
}
