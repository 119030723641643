import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/app/configs/config';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, catchError, map, Observable, pipe, retry, shareReplay, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import {
  GetOrganizationParams,
  OrganizationDTO,
} from '../../model/organization';
import { ResponseWrapper } from '../../model/common';
import { Branch } from '../../model/organization/branch';
import { UserBranchRoleAndPrivilege } from '../../model/roles-and-right/branch-role-and-privilage';
import { Department } from '../../model/department/department';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private baseUrl = environment.baseURL;

  private addBranchFlagSubject = new BehaviorSubject<boolean>(false);
  addBranchFlag$ = this.addBranchFlagSubject.asObservable();
  

  constructor(private http: HttpClient, private service: ApiService) {}

  setAddBranchFlag(flag: boolean) {
    this.addBranchFlagSubject.next(flag);
  }

  getOrganizationInfo(): Observable<any> {
    var userDetails = JSON.parse(localStorage.getItem('userDetail') as string);
    const userId = userDetails.orgId;
    // const userId = 'aca5c9e7-e160-4110-8eb4-cad9bf78e299';
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationInfo}?organizationId=${userId}`;
    return this.http.get(url);
  }

  GetFacilityLogo(branchId: string): Observable<Blob> {
    const url = `${this.baseUrl}${config.partialUrls.GetFacilityLogo}?branchId=${branchId}`;
    // return this.http.get(url);

    return this.http
      .get(url, {
        headers: {},
        responseType: 'blob', // Specify the response type as blob to handle binary data
      })
      .pipe(retry(1), shareReplay());
  }

  getIpReports(branchId:string, isOrgAdmin:boolean): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.GetIpReports}?branchId=${branchId}&isOrgAdmin=${isOrgAdmin}`;
    return this.http.get(url);
  }

  getOrganizationBranches(params: any): Observable<Branch[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationBranches}`;
    console.log(params)
    return this.http
      .get<ResponseWrapper<Branch[]>>(url, { params })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branches:', error);
          return throwError(() => error);
        })
      );
  }

  updateTotalStudents(data: any): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.UpdateTotalStudents}`;
    return this.http.post(url, data);
  }

  postOrganizationBranch(data: any): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.AddOrganizationBranch}`;
    return this.http.post(url, data);
  }

  postDepartmentAndRooms(data: any): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.AddDepartmentsAndRooms}`;
    return this.http.post(url, data);
  }

  // New Functions

  getUserOwnOrganizations(userId: string): Observable<OrganizationDTO[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetAllUserOrganizations}`;
    return this.http
      .get<ResponseWrapper<OrganizationDTO[]>>(url, {
        params: {
          userId: userId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  getOrganization(orgId: string): Observable<OrganizationDTO> {
    const params: GetOrganizationParams = {
      organizationId: orgId,
    };
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationInfo}`;
    return this.http
      .get<ResponseWrapper<OrganizationDTO>>(url, {
        params: params,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  completeBranchOnboarding(branchId: string): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.CompleteBranchOnboarding}`;
    return this.http
      .post<ResponseWrapper<null>>(url, null, {
        params: {
          organizationBranchId: branchId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  completeOnboardingAddStaffStep(branchId: string): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.CompleteBranchOnboardingAddStaff}`;
    return this.http
      .post<ResponseWrapper<null>>(url, null, {
        params: {
          organizationBranchId: branchId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  getUserBranchRolesAndRights(
    organizationBranchId: string
  ): Observable<UserBranchRoleAndPrivilege[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetUserBranchRolesAndRights}`;
    return this.http
      .get<ResponseWrapper<UserBranchRoleAndPrivilege[]>>(url, {
        params: {
          organizationBranchId: organizationBranchId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch roles and rights:', error);
          return throwError(() => error);
        })
      );
  }

  getOrganizationBranchDepartments(
    orgBranchId: string
  ): Observable<Department[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationBranchDepartments}`;
    return this.http
      .get<ResponseWrapper<Department[]>>(url, {
        params: {
          organizationBranchId: orgBranchId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  getOrganizationBranchDoctors(
    orgBranchId: string,
    departmentId?: string
  ): Observable<any> {
    const params: {
      orgBranchId: string;
      departmentId?: string;
    } = {
      orgBranchId: orgBranchId,
      departmentId: departmentId,
    };

    const url = `${this.baseUrl}${config.partialUrls.ViewDoctors}`;
    return this.http
      .get<ResponseWrapper<any[]>>(url, {
        params: params,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting Doctors:', error);
          return throwError(() => error);
        })
      );
  }


  ViewLabTechs(
    orgBranchId: string,
    departmentId?: string
  ): Observable<any> {
    const params: {
      orgBranchId: string;
      departmentId?: string;
    } = {
      orgBranchId: orgBranchId,
      departmentId: departmentId,
    };

    const url = `${this.baseUrl}${config.partialUrls.ViewLabTechs}`;
    return this.http
      .get<ResponseWrapper<any[]>>(url, {
        params: params,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting Doctors:', error);
          return throwError(() => error);
        })
      );
  }

  GetOrganizationBranchInfo(param : string){
    return this.service.get(config.partialUrls.getOrganizationBranchInfo, {
      organizationBranchId : param
    });
  }

  UpdateOrganizationBranchInfo(params: any) {
    return this.service.put(config.partialUrls.UpdateOrganizationBranchInfo, params);
  }

  UpdateBranchSchedule(params: any) {
    return this.service.put(config.partialUrls.UpdateBranchSchedule, params);
  }

  GetHospitalDepartmentRoomsAndBedsAsync(param : string){
    return this.service.get(config.partialUrls.GetHospitalDepartmentRoomsAndBedsAsync, {
      organizationBranchId : param
    });
  }

  EditDepartmentAndRooms(params: any) {
    return this.service.put(config.partialUrls.EditDepartmentAndRooms, params);
  }

  UpdateDepartmentSchedule(params: any) {
    return this.service.put(config.partialUrls.UpdateDepartmentSchedule, params);
  }

  UpdateStaffDeptSchedule(params: any) {
    return this.service.put(config.partialUrls.UpdateStaffDeptSchedule, params);
  }

  EditStaff(params: any) {
    return this.service.put(config.partialUrls.EditStaff, params);
  }

  GetUserDeptOrgBranchInfo(UserId : string, BranchId : string){
    return this.service.get(config.partialUrls.GetUserDeptOrgBranchInfo, {
      userId : UserId,
      organizationBranchId : BranchId,

    });
  }
  GetStaffSchedule(UserId : string, BranchId : string, DeptId:string){
    return this.service.get(config.partialUrls.GetStaffSchedule, {
      userId : UserId,
      branchId : BranchId,
      deptId: DeptId
    });
  }

  UploadFacilityRatecard(branchId :String,params: any) {
    return this.service.post(config.partialUrls.UploadFacilityRatecard+`?branchId=${branchId}`, params);
  }

  DownlaodProcedureList(file : string){
    return this.service.getImage(`${config.partialUrls.ExportProcedures}`, {});
  }

  GetFacilityRateCard(BranchId : string){
    return this.service.get(config.partialUrls.GetFacilityRateCard, {
      branchId : BranchId,

    });
  }

  UpdateFacilityRateCard (params: any) {
    return this.service.put(config.partialUrls.UpdateFacilityRateCard , params);
  }

  DeleteFacilityRateCard (params: any) {
    return this.service.delete(config.partialUrls.DeleteFacilityRateCard , params);
  }

  Updatecheckinflag(params: any) {
    return this.service.put(config.partialUrls.Updatecheckinflag, params);
  }



}
