export const environment = {
  production: false,
  baseURL: 'https://alpha-api.neemandtulsi.com',
  firebase: {
    apiKey: 'AIzaSyAUpBg5-S_EVVjj8bqX_M6ffFeiZ3h_ht4',
    authDomain: 'neemandtulsi-24672.firebaseapp.com',
    projectId: 'neemandtulsi-24672',
    storageBucket: 'neemandtulsi-24672.appspot.com',
    messagingSenderId: '302484290653',
    appId: '1:302484290653:web:eadc529c9866453c57a1b4',
    measurementId: 'G-VC6DRBDBNN',
    vapidKey:
      'BHTv_cGUN2dm995R5qCXCYQNSzrS7almFowyCIU8fdq6m7M3Tet_Fjh3DMAy29Yc5brwqomKukLRgaNZqLSukUM',
  },
  syncfusionKey:
    'Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCe0x0Q3xbf1x0ZFRMYFRbR3ZPMyBoS35RckVnW3lecXVXRGdfUEd0',
  domainUrl: 'http://localhost:4200',
};
